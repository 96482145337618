exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-doctors-consult-contentful-doctor-profile-slug-jsx": () => import("./../../../src/pages/doctors/consult/{ContentfulDoctorProfile.slug}.jsx" /* webpackChunkName: "component---src-pages-doctors-consult-contentful-doctor-profile-slug-jsx" */),
  "component---src-pages-doctors-consult-index-jsx": () => import("./../../../src/pages/doctors/consult/index.jsx" /* webpackChunkName: "component---src-pages-doctors-consult-index-jsx" */),
  "component---src-pages-doctors-contentful-doctor-card-slug-jsx": () => import("./../../../src/pages/doctors/{ContentfulDoctorCard.slug}.jsx" /* webpackChunkName: "component---src-pages-doctors-contentful-doctor-card-slug-jsx" */),
  "component---src-pages-doctors-index-jsx": () => import("./../../../src/pages/doctors/index.jsx" /* webpackChunkName: "component---src-pages-doctors-index-jsx" */),
  "component---src-pages-doxy-me-index-jsx": () => import("./../../../src/pages/doxy-me/index.jsx" /* webpackChunkName: "component---src-pages-doxy-me-index-jsx" */),
  "component---src-pages-hce-index-jsx": () => import("./../../../src/pages/hce/index.jsx" /* webpackChunkName: "component---src-pages-hce-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-klinika-digjitale-index-jsx": () => import("./../../../src/pages/klinika-digjitale/index.jsx" /* webpackChunkName: "component---src-pages-klinika-digjitale-index-jsx" */),
  "component---src-pages-rotary-club-peja-index-jsx": () => import("./../../../src/pages/rotary-club-peja/index.jsx" /* webpackChunkName: "component---src-pages-rotary-club-peja-index-jsx" */),
  "component---src-pages-tnmu-ukraine-index-jsx": () => import("./../../../src/pages/tnmu-ukraine/index.jsx" /* webpackChunkName: "component---src-pages-tnmu-ukraine-index-jsx" */),
  "component---src-pages-wound-care-videos-index-jsx": () => import("./../../../src/pages/wound-care-videos/index.jsx" /* webpackChunkName: "component---src-pages-wound-care-videos-index-jsx" */)
}

